.embiggener__curtain {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  /* transition: opacity 300ms ease-in-out; */
  width: 100%;
  z-index: 1400;
}

.embiggener__image {
  cursor: default;
  max-height: 80vh;
  max-width: 80vw;
}

.embiggener__close-button {
  background: none;
  border: none;
  color: white;
  content: '×';
  cursor: pointer;
  font-size: 3rem;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
}
