.main {
  position: relative;
  flex: 1;
}

.contentBeta {
  padding: 40px;
  height: 100%;
  box-sizing: border-box;
}
