.headerContainer {
  display: flex;
  align-items: center;
}

.infoCircle {
  font-size: 14px;
  color: #a3b0c1;
  margin-left: 0.5em;
}

.subHeading {
  color: #637280;
  font-size: 12px;
  letter-spacing: 0.08px;
  margin: 5px 0px;
}

.header {
  font-size: 14px;
  color: #5b7584;
  font-weight: bold;
  letter-spacing: 0;
}

.audienceSelector {
  max-width: 280px;
  margin-top: 10px;
}

.spotlightAudienceSelector {
  margin-top: 10px;
}

.teamError {
  margin-top: 10px;
  color: #df4b54;
  font-size: 12px;
  letter-spacing: 0.08px;
  margin-left: 70px;
}
