.filterByTeamContainer {
  display: flex;
  justify-items: center;
  width: inherit;
  justify-content: flex-end;
  margin: 30px;
  align-items: center;
}
.filterByTeamText {
  height: 24px;
  width: 235.5px;
  color: #637280;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 24px;
  text-align: right;
  margin-right: 4px;
}

.filterByTeamDropdown {
  height: 40px;
  width: 165px;
  margin-left: 4px;
}
