.container {
  height: 185px;
  width: 330px;
  background-color: #fff;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.21);
  position: absolute;
  z-index: 12;
  right: 0;
  padding: 20px;
  text-align: left;
}

.heading {
  color: #df4b54;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.icon {
  margin-right: 0.5em;
}

.subHeading {
  color: #637280;
  font-size: 16px;
  letter-spacing: 0.11px;
  margin-bottom: 30px;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border-radius: 30px !important;
  height: 45px !important;
  width: 121px !important;
  font-size: 16px !important;
  letter-spacing: 0.11px !important;
}

.btn:hover {
  font-weight: 400 !important;
  background-color: #1257b3 !important;
}

.btnSecondary {
  border-radius: 30px !important;
  height: 45px !important;
  width: 162px !important;
  font-size: 16px !important;
  letter-spacing: 0.11px !important;
  margin-right: 26px !important;
}

.btnSecondary:hover {
  font-weight: 400 !important;
  background-color: #6f8497 !important;
}

.cancel {
  color: #3576e8;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 20px;
  cursor: pointer;
}
