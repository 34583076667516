.main {
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  background-color: #f8f9fa;
  color: #637280;
  text-align: left;
  padding: 72px 10px 0px;
  width: 155px;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.link {
  margin: 7.5px 0px;
  text-align: left;
  font-size: 12px;
  text-decoration: none;
  color: var(--spekit-colors-neutral-800);
  padding: 8px 12px;
  font-weight: 600;
  cursor: pointer;
}

.link:not(.activeLink):hover {
  background-color: var(--spekit-colors-primary-200);
}

.activeLink {
  composes: link;
  background-color: var(--spekit-colors-primary-100);
}

.link:hover,
.activeLink {
  border-radius: 8px;
}
