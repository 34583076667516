.optionWrapper {
  font-size: 10px;
}

.cardWrapper {
  position: absolute;
  top: 0;
  left: -300px;
  z-index: 10;
  border-radius: 3.32px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
}

.option {
  display: inline-block;
  padding: 2px;
  margin-right: 0.3em;
  border-radius: 3px;
  background-color: #dddddd;
  color: #637280;
}

.content {
  background-color: #fff;
  padding: 24px 16px;
  transition: all 300ms ease-in-out;
  height: 245px;
  width: 245px;
}
.contentLoading {
  background-color: #fff;
  padding: 24px 16px;
  transition: all 300ms ease-in-out;
  height: 245px;
  width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  position: absolute;
  top: 24px;
  right: 16px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.label {
  color: #354052;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 212px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.definition {
  color: #637280;
  font-size: 12px;
  line-height: 19px;
  height: 100px;
  overflow: hidden;
  margin-top: 20px;
  text-align: center;
  text-overflow: ellipsis;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
}

.emdash {
  align-self: flex-start;
  font-weight: 700;
}

.emptyAddBtn {
  background-color: #eaf1fc !important;
  color: #3576e8 !important;
  border: 1px solid #3576e8 !important;
  margin-top: 16px !important;
  align-self: center !important;
}

.footer {
  background-color: #ebeff5;
  color: #637280;
  padding: 20px;
  height: 62.6px;
  font-size: 11px;
  font-weight: 300;
  transition: all 300ms ease-in-out;
}

.footerText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerIcons {
  margin-right: 1em !important;
}

.undefinedFooter {
  background-color: #fff;
  color: #637280;
  padding: 20px;
  height: 62.6px;
  font-size: 11px;
  font-weight: 300;
  transition: all 300ms ease-in-out;
}

.titleWrapper {
  display: inline-flex;
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
}
