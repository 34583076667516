.header {
  color: #637280;
  font-family: 'Open Sans';
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 15px;
  text-align: right;
  height: 15px;
}

.headerPendingViewersContainer {
  height: 19px;
  width: 208px;
}

.headerPendingViewersText {
  composes: header;
  width: 196px;
}

.headerUniqueViewers {
  composes: header;
  width: 111px;
}

.headerEngaged {
  composes: header;
  width: 63px;
}

.headerSnoozedContainer {
  height: 19px;
  width: 90px;
}

.headerSnoozedText {
  composes: header;
  width: 78px;
}

.headerAvgTimeSpentContainer {
  height: 19px;
  width: 180px;
}

.headerAvgTimeSpentText {
  composes: header;
  width: 167px;
}

.headingAnalyticsOverview {
  height: 26px;
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 24px;
}

.emptyState {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #637280;
  font-family: 'Open Sans';
  letter-spacing: 1.5px;
  line-height: 15px;
}
