.container {
  width: 100%;
  height: 65%;
}

.empty {
  width: 100%;
  height: 100%;
  background-color: #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pencil {
  color: #5f7282;
  font-size: 14px;
}

.text {
  color: #354052;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.08px;
  margin-top: 10px;
}

.image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.systemImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: cover !important;
  cursor: pointer;
}

.embed {
  width: 100%;
  height: 100%;
}

.embedContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.embedOverlay {
  z-index: 5;
  background-color: #000;
  opacity: 0.7;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.08px;
  font-weight: 300;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(2.5);
  flex-direction: column;
  cursor: pointer;
}
.loadingContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #3576e8;
  background-color: #f1f3f6;
  height: 100%;
  width: 100%;
}
