.pagination_container {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 10px;
}

.table_content {
  font-size: 9px;
}

.header {
  color: #637280;
  font-family: 'Open Sans';
  font-size: 11px;
  letter-spacing: 1.5px;
  line-height: 15px;
  text-align: right;
  height: 15px;
}

.headerViewers {
  composes: header;
  width: 48px;
}

.headerViewed {
  composes: header;
  width: 50px;
}

.headerEngaged {
  composes: header;
  width: 83px;
}

.headerLastViewed {
  composes: header;
  width: 85px;
}

.headerSnoozedContainer {
  height: 19px;
  width: 104px;
}

.headerSnoozedText {
  composes: header;
  width: 93px;
}

.headerAvgTimeSpentContainer {
  height: 19px;
  width: 180px;
}

.headerAvgTimeSpentText {
  composes: header;
  width: 167px;
}

.headingAnalyticsByUser {
  height: 26px;
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 24px;
  margin-top: 36px;
  margin-bottom: 24px;
}

.emptyState {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #637280;
  font-family: 'Open Sans';
  letter-spacing: 1.5px;
  line-height: 15px;
  font-size: 24px;
}

.avatarContainer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
