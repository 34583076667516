.container {
  display: flex;
  flex-direction: column;
}

.title {
  height: 58px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  font-size: 16px;
  color: #617381;
  background-color: #fff;
  border-top: 1px solid #d4dde6;
  cursor: pointer;
  letter-spacing: 0.11px;
  transition: all 300ms ease-in-out;
}

.titleActive {
  height: 58px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  align-items: center;
  font-size: 16px;
  color: #617381;
  background-color: #f9f9f9;
  border-top: 1px solid #d4dde6;
  cursor: pointer;
  letter-spacing: 0.11px;
  transition: all 300ms ease-in-out;
}

.title:hover,
.titleActive:hover {
  background-color: #f9f9f9;
  transition: all 300ms ease-in-out;
}

.chevron {
  font-size: 13px;
}

.content {
  /* overflow: auto; */
  transition: all 300ms ease-in-out;
}

/* .hiddenContent {
  overflow: hidden;
  transition: all 300ms ease-in-out;
} */
