.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  height: 100vh;
}
.header {
  font-weight: 300;
  font-size: 38px;
  color: #637780;
  margin-bottom: 37px;
}
.getstartedBtn {
  color: #3576e8;
  cursor: pointer;
  text-decoration: none;
}
.registerContainer {
  font-size: 14px;
  font-weight: 500;
  color: #637280;
  position: absolute;
  top: 16px;
  right: 48px;
  text-align: right;
}
.errorBox {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  width: 80%;
  min-width: 320px;
  max-width: 800px;
  box-sizing: border-box;
  margin: 0px auto 90px;
  color: #637280;
}
.errorContainer {
  display: flex;
  gap: 3em;
  margin-top: 40px;
}
.back {
  margin-left: 37px;
  color: #3576e8;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 35px;
  cursor: pointer;
}
.backIcon {
  margin-right: 0.5em;
  color: #3576e8;
}
.errorIcon {
  font-size: 16px;
  color: #df4b54;
  margin-right: 6px;
}
.heading {
  color: #4a4a4a;
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 33px;
}
.description {
  color: #313e47;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 63px;
}
.messageContainer {
  text-align: left;
}
.licenseEmail {
  color: #1a75f0;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 16px;
  text-align: right;
  cursor: pointer;
  margin: 0px 0.5em;
}
