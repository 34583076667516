.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.label {
  color: #637280;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0.08px;
  line-height: 17px;
}

.label > * {
  margin-left: 0.5em;
}

.multimediaContainer {
  height: 145px;
  width: 153px;
  margin-top: 5px;
  box-sizing: border-box;
}

.multimedia {
  border: 0.89px solid #c0c5d2;
  padding: 7px;
  height: 100%;
  width: 100%;
}

.multimediaReadable {
  height: 100%;
  width: 100%;
}
.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
