.pageContainer {
  display: grid;
  place-items: center;
  align-content: center;
  height: 100vh;
}
.iconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 50px;
}
.spekitIcon {
  width: 86px;
  height: 76px;
  margin-right: 20px;
}
.errorContainer {
  width: 48px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rectangle {
  height: 1.5px;
  width: 15px;
  background-color: #dddddd;
}
.errorIcon {
  color: #56606a;
  width: 11px;
  height: 11px;
  display: flex;
}
.ssoIcon {
  width: 64px;
  border: 3px solid #000000;
  border-radius: 50%;
  height: 64px;
  margin-right: 10px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconkey {
  font-size: 32px;
}
.ssoIconContainer {
  justify-content: center;
  display: flex;
  align-items: center;
}
.statusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-right: 34px;
}
.exclamationIcon {
  font-size: 28px;
}
.errorText {
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 57px;
  color: #222222;
}
.errorDescription {
  color: #313e47;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}
.contactDescription {
  color: #313e47;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  display: flex;
}
.email {
  color: #1a75f0;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0.11px;
  padding-left: 0.25em;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
