/* override CKEditor css variables */
:root {
  --ck-default-z: 10000000000 !important;
  --ck-z-modal: 10000000000 !important;
  --ck-z-panel: 10000000000 !important;
}

/* content in the editor was not wrapping by default when displayed in our app */
.spekit-ck-content.ck-content {
  white-space: normal !important;
  word-break: break-word;
  line-height: 24px !important;
  font-family: 'Open Sans', sans-serif !important;
  /* 384 min height of editor - 40px for toolbar */
  min-height: 344px;
  max-height: 560px;
  font-size: 14px !important;
  padding: 6px var(--ck-spacing-standard) !important;
  color: var(--spekit-colors-neutral-80) !important;
  box-sizing: border-box;
}

.spotlight-editor .spekit-ck-content.ck-content {
  min-height: 120px;
}

.spotlight-editor-compact .spekit-ck-content.ck-content {
  min-height: 24px;
}

.spekit-ck-content.ck-content .ck-system {
  font-size: 10px !important;
}

/* TABLES */
.spekit-ck-content.ck-content .table {
  max-width: 100%;
}

.spekit-ck-content.ck-read-only.ck-chrome-extension .table {
  width: auto !important;
  display: block;
}

.spekit-ck-content.ck-read-only.ck-chrome-extension table {
  display: block;
  overflow-x: auto;
}

.spekit-ck-content.ck-content .table table th,
.spekit-ck-content.ck-content .table table td {
  min-width: var(--spekit-sizes-12) !important;
  word-break: normal !important;
}

/* no top border on top row cells */
.spekit-ck-content.ck-content .table table tr:first-child th,
.spekit-ck-content.ck-content .table table tr:first-child td {
  border-top: none !important;
}

/* no bottom border on last row cells */
.spekit-ck-content.ck-content .table table tr:last-child td {
  border-bottom: none !important;
}

/* no left border on first column cells */
.spekit-ck-content.ck-content .table table tr th:first-child,
.spekit-ck-content.ck-content .table table tr td:first-child {
  border-left: none !important;
}

/* no right border on last column cells */
.spekit-ck-content.ck-content .table table tr th:last-child,
.spekit-ck-content.ck-content .table table tr td:last-child {
  border-right: none !important;
}

.spekit-ck-content.ck-content .table table tr:first-child th:first-child,
.spekit-ck-content.ck-content .table table tr:first-child td:first-child {
  border-top-left-radius: var(--spekit-radii-6);
}

.spekit-ck-content.ck-content .table table tr:first-child th:last-child,
.spekit-ck-content.ck-content .table table tr:first-child td:last-child {
  border-top-right-radius: var(--spekit-radii-6);
}

.spekit-ck-content.ck-content .table table tr:last-child td:first-child {
  border-bottom-left-radius: var(--spekit-radii-6);
}

.spekit-ck-content.ck-content .table table tr:last-child td:last-child {
  border-bottom-right-radius: var(--spekit-radii-6);
}
/* END TABLES */

/* MENTIONS */
/* the balloon panel sits outside the editor and is not scoped behind the spekit-ck-content class */
.ck.ck-balloon-panel {
  border: 1px solid var(--spekit-colors-neutral-20) !important;
  border-radius: var(--spekit-radii-12) !important;
  box-shadow: none !important;
}
.ck-balloon-rotator__content > * {
  border-radius: var(--spekit-radii-12) !important;
}
/* the class applied to each mention list item */
.spek-mention-item {
  display: flex;
  align-items: center;
  gap: var(--spekit-space-4);
  cursor: pointer;
}

.spek-mention-item svg {
  height: var(--spekit-sizes-4) !important;
  fill: var(--spekit-colors-neutral-80);
}

.ck-mentions .ck-list__item {
  display: flex !important;
}

.ck-mentions .ck-list__item .ck-button.ck-on {
  color: var(--spekit-colors-primary-500) !important;
  background: var(--spekit-colors-primary-25) !important;
}

.ck-mentions .ck-list__item .ck-button.ck-on svg {
  fill: var(--spekit-colors-primary-500) !important;
}

.ck-mentions .ck-list__item .ck-button.ck-on * {
  color: var(--spekit-colors-primary-500) !important;
}

.ck-mentions .ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: var(--spekit-colors-neutral-2) !important;
}

.spekit-ck-content.ck-content .mention {
  padding: var(--spekit-space-2) var(--spekit-space-4);
  border-radius: var(--spekit-radii-4);
  cursor: pointer;
}

.spekit-ck-mention-pdf {
  --ck-color-mention-background: var(--spekit-colors-pdf-25) !important;
  --ck-color-mention-text: var(--spekit-colors-pdf-500) !important;
}

a.spekit-ck-mention-pdf:hover {
  color: var(--spekit-colors-pdf-500) !important;
}

.spekit-ck-mention-document {
  --ck-color-mention-background: var(--spekit-colors-document-25) !important;
  --ck-color-mention-text: var(--spekit-colors-document-500) !important;
}

a.spekit-ck-mention-document:hover {
  color: var(--spekit-colors-document-500) !important;
}

.spekit-ck-mention-presentation {
  --ck-color-mention-background: var(--spekit-colors-presentation-25) !important;
  --ck-color-mention-text: var(--spekit-colors-presentation-500) !important;
}

a.spekit-ck-mention-presentation:hover {
  color: var(--spekit-colors-presentation-500) !important;
}

.spekit-ck-mention-spreadsheet {
  --ck-color-mention-background: var(--spekit-colors-spreadsheet-25) !important;
  --ck-color-mention-text: var(--spekit-colors-spreadsheet-500) !important;
}

a.spekit-ck-mention-spreadsheet:hover {
  color: var(--spekit-colors-spreadsheet-500) !important;
}

/* END MENTIONS */

/* wrapper for embedded files */
.spekit-ck-content.ck-content .upload-embed {
  /* max-width: 400px;
  min-width: 300px; */
  display: inline-block;
}

/* wrapper for embedded iframes */
.spekit-ck-content.ck-content .iframe-embed {
  max-width: 800px;
  margin: 20px auto;
}

/* when editing, our react components are wrapped in another wrapper
* we need to remove the iframe margin when it is wrapped in this way
*/
.spekit-ck-content.ck-content .iframe-embed > * {
  margin: 0 !important;
}

/* this class is only used to wrap embedded react components while editing
we prevent pointer events so that the user can select the element as a block */
.spekit-ck-content.ck-content .ck-editor__react-wrapper {
  pointer-events: none;
}

.spekit-ck-content.ck-content img {
  cursor: pointer;
  max-width: 100% !important;
  height: auto;
}

/* style links appropriately */
.spekit-ck-content.ck-content a {
  color: var(--spekit-colors-default-primary-500);
  text-decoration: none;
}

.spekit-ck-content.ck-content a:hover {
  text-decoration: none;
  color: var(--spekit-colors-default-primary-800);
  transition: all 0.5s ease-out;
}

/* use our design system heading sizes  */
.spekit-ck-content.ck-content h1 {
  font-weight: unset;
  font-size: var(--spekit-fontSizes-massive);
  line-height: 1.33;
}
.spekit-ck-content.ck-content h2 {
  font-weight: unset;
  font-size: var(--spekit-fontSizes-jumbo);
  line-height: 1.33;
}
.spekit-ck-content.ck-content h3 {
  font-weight: unset;
  font-size: var(--spekit-fontSizes-xl);
  line-height: 1.33;
}
.spekit-ck-content.ck-content h4 {
  font-weight: unset;
  font-size: var(--spekit-fontSizes-large);
  line-height: 1.33;
}

/* match paragraph spacing of RTE by removing margin */
.spekit-ck-content.ck-content p {
  line-height: 24px !important;
  font-family: 'Open Sans', sans-serif !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* match design system captions */
.spekit-ck-heading_caption {
  font-size: var(--spekit-fontSizes-tiny) !important;
}

.spekit-ck-content.ck-content .ck-link_selected {
  background: none;
}

.spekit-ck-content.ck-content > ul ul,
.spekit-ck-content.ck-content > ol ol {
  padding-left: 1.5em;
}

.spekit-ck-content.ck-content > ul ol,
.spekit-ck-content.ck-content > ol ul {
  padding-left: 1.5em;
}

/* we list indent to 10 level and apply custom styles for consistency */
/* unordered list styles */
.spekit-ck-content.ck-content ul {
  list-style-type: disc !important;
}

.spekit-ck-content.ck-content ul ul {
  list-style-type: circle !important;
}

.spekit-ck-content.ck-content ul ul ul {
  list-style-type: square !important;
}

.spekit-ck-content.ck-content ul ul ul ul {
  list-style-type: disc !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul {
  list-style-type: circle !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul {
  list-style-type: square !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul ul {
  list-style-type: disc !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul ul ul {
  list-style-type: circle !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul ul ul ul {
  list-style-type: square !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul ul ul ul ul {
  list-style-type: disc !important;
}

.spekit-ck-content.ck-content ul ul ul ul ul ul ul ul ul ul ul {
  list-style-type: circle !important;
}

/* ordered list styles */
.spekit-ck-content.ck-content ol {
  list-style-type: decimal !important;
}

.spekit-ck-content.ck-content ol ol {
  list-style-type: lower-latin !important;
}

.spekit-ck-content.ck-content ol ol ol {
  list-style-type: lower-roman !important;
}

.spekit-ck-content.ck-content ol ol ol ol {
  list-style-type: decimal !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol {
  list-style-type: lower-latin !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol {
  list-style-type: lower-roman !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol ol {
  list-style-type: decimal !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol ol ol {
  list-style-type: lower-latin !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol ol ol ol {
  list-style-type: lower-roman !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol ol ol ol ol {
  list-style-type: decimal !important;
}

.spekit-ck-content.ck-content ol ol ol ol ol ol ol ol ol ol ol {
  list-style-type: lower-latin !important;
}

/* custom indent styles */
/* https://ckeditor.com/docs/ckeditor5/latest/features/indent.html#using-css-classes */
.custom-block-indent-a {
  margin-left: 2em;
}

.custom-block-indent-b {
  margin-left: 4em;
}

.custom-block-indent-c {
  margin-left: 6em;
}

.custom-block-indent-d {
  margin-left: 8em;
}

.custom-block-indent-e {
  margin-left: 10em;
}

.custom-block-indent-f {
  margin-left: 12em;
}

.custom-block-indent-g {
  margin-left: 14em;
}

.custom-block-indent-h {
  margin-left: 16em;
}

.custom-block-indent-i {
  margin-left: 18em;
}

.custom-block-indent-j {
  margin-left: 20em;
}

/* render all text at 12px in previews */
.ck-preview.ck-content,
.ck-preview * {
  font-size: 12px !important;
  font-weight: normal !important;
  margin: 0 !important;
  line-height: 1.6rem !important;
  /* CKE applies a default white background to text. we remove it in previews.  */
  background-color: rgba(0, 0, 0, 0) !important;
}

/* remove padding, border, and height limits in read-only mode */
.spekit-ck-content.ck-read-only {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  border: none !important;
  min-height: 0 !important;
  max-height: none !important;
  overflow: auto !important;
}

.spotlight-editor .spekit-ck-content.ck-read-only {
  padding: 6px var(--ck-spacing-standard) !important;
  color: var(--spekit-colors-neutral-500) !important;
  border: 1px solid transparent !important;
  border-color: var(--spekit-colors-gray-200) !important;
  background: var(--spekit-colors-neutral-25) !important;
  min-height: 120px !important;
}

.spotlight-editor-compact .spekit-ck-content.ck-read-only {
  padding: 6px var(--ck-spacing-standard) !important;
  color: var(--spekit-colors-neutral-500) !important;
  border: 1px solid transparent !important;
  border-color: var(--spekit-colors-gray-200) !important;
  background: var(--spekit-colors-neutral-25) !important;
  min-height: 24px !important;
}

.notification-item .spekit-ck-content.ck-read-only {
  font-size: inherit !important;
  font-family: inherit !important;
}

/* To show ellipsis on the first line if text is overflowing for webapp in read-only mode */
.spekit-ck-content.ck-read-only.search-preview-webapp {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  padding-bottom: 0 !important;
}

/* To show ellipsis on the second line if text is overflowing for extension in read-only mode */
.spekit-ck-content.ck-read-only.search-preview-extension {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  padding-bottom: 0 !important;
}

.spekit-ck-content.ck-read-only.search-preview-extension.ck-content p,
.spekit-ck-content.ck-read-only.search-preview-webapp.ck-content p {
  line-height: 18px !important;
}
