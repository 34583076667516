.container {
  display: grid;
  grid-template-columns: 52% 48%;
}

.grid {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 200px 1fr;
  padding-right: 20px;
}

.hr {
  margin: 20px -10px 20px -10px;
  border-top: 0.1px solid #bec4d06b;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.infoCircle {
  font-size: 14px;
  color: #a3b0c1;
  margin-left: 0.5em;
}

.subHeading {
  color: #637280;
  font-size: 12px;
  letter-spacing: 0.08px;
  margin: 5px 0px;
}

.subHeadingDestination {
  color: #637280;
  font-size: 12px;
  letter-spacing: 0.08px;
  margin: 5px 0px 5px 110px;
}

.labelsContainer {
  display: inline-flex;
  width: 40%;
  justify-content: space-between;
}

.infoCircleSmall {
  font-size: 12px;
  color: #a3b0c1;
  margin-left: 0.3em;
}
