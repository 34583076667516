.optionWrapper {
  font-size: 10px;
}

.cardWrapper {
  position: absolute;
  top: 0;
  left: -300px;
  z-index: 10;
  border-radius: 3.32px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
}

.option {
  display: inline-block;
  padding: 2px;
  margin-right: 0.3em;
  border-radius: 3px;
  background-color: #dddddd;
  color: #637280;
}

.imageIcon {
  margin: 0 0.3em;
  vertical-align: sub;
}

.fawesome {
  font-size: 14px;
  margin: 0 0.3em;
  color: #3576e8;
}

.content {
  background-color: #fff;
  padding: 16px 16px 24px 16px;
  transition: all 300ms ease-in-out;
  height: 152px;
  width: 245px;
}

.avatar {
  position: absolute;
  top: 15px;
  left: 16px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  margin-right: 0.5em;
}

.favatar {
  position: absolute !important;
  top: 15px !important;
  left: 16px !important;
  height: 40px !important;
  width: 40px !important;
  overflow: hidden !important;
  margin-right: 0.5em !important;
  color: #3576e8 !important;
}

.label {
  color: #354052;
  font-weight: 600;
  letter-spacing: 0.11px;
  font-size: 16px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 0px 0px 56px;
}

.hr {
  border: 1px solid rgba(159, 169, 186, 0.23);
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
}

.description {
  margin: 15px 0px 5px 0px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.08px;
  height: 64px;
  color: #354052;
  line-height: 16px;
  overflow: hidden;
}
.footer {
  display: flex;
  align-items: baseline;
  color: #637280;
  padding: 10px 0px;
  height: 11.6px;
  font-size: 11px;
  font-weight: 300;
  transition: all 300ms ease-in-out;
}

.domainAvatar {
  height: 16px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  color: #637280;
  font-weight: 300;
  border-radius: 8px;
  margin-right: 7px;
  background-color: #ced4e0;
}

.domains {
  color: #637280;
  font-size: 11px;
  font-weight: 300;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.3em;
}

.contentLoading {
  background-color: #fff;
  padding: 24px 16px;
  transition: all 300ms ease-in-out;
  height: 245px;
  width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}
