.container {
  width: 339px;
  text-align: left;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 12;
  right: 0;
  justify-content: space-between;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.21);
}

.heading {
  color: #df4b54;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.subHeading {
  color: #637280;
  font-size: 16px;
  letter-spacing: 0.11px;
  margin-bottom: 20px;
}

.icon {
  margin-right: 0.5em;
}

.primaryButton {
  height: 45px !important;
  min-width: 83px !important;
  border-radius: 30px !important;
  margin-right: 30px !important;
  padding: 0px 20px !important;
}

.secondaryButton {
  height: 45px !important;
  border-radius: 30px !important;
  padding: 0px 25px !important;
}

.cancel {
  color: #3576e8;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 20px;
  cursor: pointer;
}
