.content {
  text-align: center;
  padding-top: 120px;
}
.header {
  font-size: 38px;
  color: #637780;
  margin-bottom: 37px;
}
.loginBox {
  padding: 60px 70px;
  background-color: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 490px;
  box-sizing: border-box;
  margin: auto;
}
.loginBtn {
  width: 100%;
  font-size: 16px !important;
  padding: 14px 0px !important;
  border-radius: 24px !important;
}
