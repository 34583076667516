.chromeStatusBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.statusBar {
  align-items: center;
  background-color: #f1f3f5;
  color: #343a40;
  display: flex;
  font-size: 12px;
  height: 36px;
  line-height: 11px;
}

.webStatusBar {
  width: 155px;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 10px;
}

a.statusBarItem,
a.statusBarItem:active,
a.statusBarItem:visited,
a.statusBarItem:hover {
  color: #343a40;
  text-decoration: none;
}

.statusBarItem {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  margin-right: 12px;
  padding: 4px;
}

.webStatusBar .statusBarItem {
  margin-right: 4px;
}

.statusBarItem.menuOpen {
  background-color: #e0d8fa !important;
}

.statusBarItem:first-child {
  margin-left: 6px !important;
}

.statusBarItem:last-child {
  margin-right: 0 !important;
}

.statusBarItem > span {
  pointer-events: none;
}

.statusBarItem > span:first-child {
  margin-right: 2px;
}

.statusBarItem:hover {
  background-color: rgba(120, 124, 196, 0.26);
}
