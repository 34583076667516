.closeBtn {
  font-size: 28px !important;
  top: 5px !important;
  color: #c0c5d2 !important;
  margin-right: 20px;
  margin-top: 2px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.header {
  height: 45.6px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #637280;
  font-size: 16px;
}

.headerHelperIcon {
  margin-left: 0.5em;
}

.content {
  overflow: auto;
  margin: 12px 20px 20px 20px;
  text-align: left;
  max-height: calc(100vh - 160px);
}

.indicatorContainer {
  margin-left: 692px;
}

.indicator {
  color: #2b2826;
  font-size: 11.11px;
  letter-spacing: 0;
}

.theSpot {
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
  background-color: #637280;
  width: 8px;
  height: 8px;
}

.thePublishedSpot {
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
  background-color: #0edd4d;
  width: 8px;
  height: 8px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}
