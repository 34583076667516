.container {
  width: 100%;
  height: 30%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.89px solid #c0c5d2;
  height: 100%;
  width: 32%;
  color: #bec4d0;
  cursor: pointer;
  position: relative;
  outline: none;
}

.item:first-child,
.item:nth-child(2) {
  border-right: none;
}

.input {
  display: none;
}

.popoverContainer {
  position: absolute;
  top: 46px;
  left: 5px;
  z-index: 2;
}

.libraryContainer {
  position: absolute;
  top: 50px;
  left: 101px;
  border: 1px solid #c0c5d2;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 -2px 12px 0 rgba(159, 169, 186, 0.8);
  padding: 15px;
  box-sizing: border-box;
  z-index: 2;
}

.popover {
  height: 150px;
  width: 400px;
  border: 1px solid #3576e8;
  resize: none;
  color: #56606a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  font-family: 'Open Sans';
  padding: 5px;
  background-color: #ffffff;
  outline: none;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.popover::placeholder {
  line-height: 20px;
}

.embedButtonContainer {
  height: 40px;
  background-color: #ffffff;
  position: absolute;
  top: 161px;
  border: 1px solid #3576e8;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  width: 400px;
  padding: 5px;
}

.embedButton {
  height: 35px !important;
  width: 141px !important;
  position: absolute !important;
  bottom: 8px !important;
  right: 5px !important;
}

.icon {
  color: #9fa9ba;
  font-size: 14px;
}

.uploadItem {
  /* padding: 10px 20px; */
  cursor: pointer;
}

.libraryGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  overflow: auto;
  height: 260px;
}

.graphicText {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
}

.gridItem {
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  width: 213px;
  height: auto;
}
.gridItem:hover {
  border: 1px solid #3576e8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.menuPopover {
  border: 1px solid #c0c5d2;
  position: absolute;
  bottom: 47px;
  left: 0px;
  border-radius: 4px;
  box-shadow: 1px 2px 10px 0 rgba(159, 169, 186, 0.8);
  outline: none;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  width: 220px;
  flex-wrap: wrap;
  z-index: 5;
}

.menuPopover span {
  padding: 10px 20px;
  color: #637280;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
  flex: 0 0 100%;
}

.menuPopover span:hover {
  background-color: #f6f7f9;
}
